<template>
  <div class="view">
    <my-nav :crems="crems"></my-nav>
    <div class="content">
      <div :class="['box1',flg?'boxactive':'']">
        <div class="txt1">{{ rs.title }}</div>
        <div class="txt2">
          <span>{{ time }}</span>
          <span>{{ rs.source }}</span>
          <span>浏览量{{ rs.pviews }}</span>
        </div>
        <div class="txt3" v-html="rs.content"></div>
      </div>
      <div class="foot" v-if="!flg">
        <div class="left">
          <div v-if="rs.prev_id" @click="toOther(rs.prev_id)">
            <span class="last">上一篇</span>
            <span ref="domr">{{ rs.prev_title }}</span>
          </div>
          <div v-if="rs.next_id" @click="toOther(rs.next_id)">
            <span class="next">下一篇</span>
            <span ref="doml">{{ rs.next_title }}</span>
          </div>
        </div>

        <div class="right" @click="route()">
          返回列表
          <img src="../assets/img01/fk.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myNav from "@/components/esgnav";
export default {
  components: {
    myNav,
  },
  data() {
    return {
      goods_id: 58,
      rs: {},
      crems: {},
      time: "",
      ctype: "",
      flg:true,
    };
  },
  mounted() {
    // console.log(JSON.parse(this.$route.query.crems))
    this.$store.commit("until/SET_COLOR", "#000");
    this.goods_id = this.$route.query.id;
    this.ctype = this.$route.query.ctype;
    this.flg = this.$route.query.flg
    this.getdata();
    console.log(1);
    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
    if (this.$route.query.crems)
      this.crems = JSON.parse(this.$route.query.crems);
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
  methods: {
    getdata() {
      this.$fetch({
        url: "index.php/news/info",
        type: "post",
        data: this.$qs.stringify({ id: this.goods_id }),
      }).then((res) => {
        this.rs = res.data.rs;
        this.time = res.data.rs.timeline;
        this.$nextTick(() => {
          this.Time(this.time);
          this.getx(this.rs.prev_title,"prev");
          this.getx(this.rs.next_title,"next");
        });
      });
    },
    toOther(id) {
      this.goods_id = id;
      this.getdata();
      this.$router.push({ path: "/esgview", query: { id: id } });
    },
    route(cid) {
        this.$router.push({
          path: "esglist",
        });
    },
    getx(dom,str) {
      var arr = dom;
      if (arr.length > 19) {
        for(var i =0; i<arr.length; i++){  
          if(str == 'prev'){
            this.rs.prev_title = arr.slice(0,20) + '...'
          }
          else{
            this.rs.next_title = arr.slice(0,20) + '...'
          }
        }
      }
    },
    //时间戳
    Time(em) {
      this.time = this.$until.timestampToTime(em);
    },
    //浏览器默认返回
    goBack() {
      // this.goods_id = this.rs.prev_id;
      // this.getdata();
        // sessionStorage.clear();
        window.history.back();
        history.pushState(null, null, document.URL);
    },
  },
};
</script>

<style scoped>
.view .txt3 >>> img {
  display: inline-block;
}
.box1 {
  min-height: 500px;
}
.box1 .txt1 {
  font-size: 28px;
  color: #333333;
  margin-top: 60px;
  font-weight: 600;
}
.box1 .txt2 {
  margin: 20px 0;
  position: relative;
  color: #666;
  font-size: 14px;
}
.box1 .txt2::after {
  content: "";
  width: 800px;
  height: 1px;
  background: #d8d8d8;
  opacity: 0.8;
  position: absolute;
  bottom: -15px;
  left: 0;
}
.box1 .txt2 span:nth-child(2) {
  margin: 0 12px;
}
.box1 .txt3 {
  margin-top: 50px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  text-align: justify;
}
.content {
  width: 800px;
  margin: 0 auto;
}
.foot {
  width: 800px;
  margin: 40px 0;
  background: #f7f7f7;
  display: flex;
  justify-content: space-between;
}
.right {
  display: flex;
  align-items: center;
  color: #396aff;
  cursor: pointer;
  font-size: 14px;
}
.right img {
  width: 15px;
  height: 12px;
  margin-left: 12px;
  margin-right: 37px;
}
.left > div {
  margin: 14px;
}
.left > div:hover span {
  color: #396aff;
}
.left span {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.last,
.next {
  margin-right: 15px;
  cursor: pointer;
}
@media (min-width: 1280px) {
  .boxactive{
    margin-bottom: 40px;
  }
  .box1 {
    min-height: 500px;
  }
  .box1 .txt1 {
    font-size: 28px;
    color: #333333;
    margin-top: 60px;
    font-weight: 600;
  }
  .box1 .txt2 {
    margin: 20px 0;
    position: relative;
    color: #666;
    font-size: 14px;
  }
  .box1 .txt2::after {
    content: "";
    width: 800px;
    height: 1px;
    background: #d8d8d8;
    opacity: 0.8;
    position: absolute;
    bottom: -15px;
    left: 0;
  }
  .box1 .txt2 span:nth-child(2) {
    margin: 0 12px;
  }
  .box1 .txt3 {
    margin-top: 50px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
  }
  .content {
    width: 800px;
    margin: 0 auto;
  }
  .foot {
    width: 800px;
    margin: 40px 0;
    background: #f7f7f7;
    display: flex;
    justify-content: space-between;
  }
  .right {
    display: flex;
    align-items: center;
    color: #396aff;
    cursor: pointer;
    font-size: 14px;
  }
  .right img {
    width: 15px;
    height: 12px;
    margin-left: 12px;
    margin-right: 37px;
  }
  .left > div {
    margin: 14px;
  }
  .left > div:hover span {
    color: #396aff;
  }
  .left span {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .last,
  .next {
    margin-right: 15px;
    cursor: pointer;
  }
}
</style>
<style>
.view .content .box1 .txt3 img {
  max-width: 800px !important;
}
.view .content .box1 .txt3 {
  color: #000;
}
</style>