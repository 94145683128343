import { render, staticRenderFns } from "./esgview.vue?vue&type=template&id=52965987&scoped=true"
import script from "./esgview.vue?vue&type=script&lang=js"
export * from "./esgview.vue?vue&type=script&lang=js"
import style0 from "./esgview.vue?vue&type=style&index=0&id=52965987&prod&scoped=true&lang=css"
import style1 from "./esgview.vue?vue&type=style&index=1&id=52965987&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52965987",
  null
  
)

export default component.exports